@use 'sass:math';

/**
 * Site header
 */
.site-header {
  border-bottom: 1px solid $grey-color-light;
  min-height: 56px;
  background-color: $header-color-light;

  // Positioning context for the mobile navigation icon
  position: relative;

  .row {
    align-items: flex-end;
    flex-wrap: wrap;
    // overwrite default row behaviour in collapsed mode
    flex-direction: row;

    @include media-query($on-palm) {
      > * {
        flex-grow: 1;
      }
    }
  }
}

.site-title {
  font-family: Lato;
  font-size: 26px;
  font-weight: 700;
  font-style: italic;
  line-height: 56px;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: #09333f;
  }
}

.site-nav {
  float: right;
  line-height: 56px;
  font-weight: 700;
  font-family: Lato;
  font-style: italic;

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $brand-white;
    background: $brand-color;
    padding: 0.5rem;
    line-height: $base-line-height * 2;

    &.page-highlight {
      background: $brand-green;
    }

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: .5rem;
    }

    &:hover {
      background: $contrast-color;
      text-decoration: none;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: math.div($spacing-unit, 2);
    background-color: $background-color;
    text-align: right;

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        width: 18px;
        height: 15px;

        path {
          fill: $grey-color-dark;
        }
      }
    }

    .trigger {
      clear: both;
      display: none;
    }

    &:hover .trigger,
    &:focus .trigger,
    &:active .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}

.site_logo {
  height: 80px;
  padding: 10px;
}

.intro {
  background: $brand-color;
  color: $brand-white;
  padding: math.div($spacing-unit, 2);

  .intro-img-col {
    max-width: 15rem;
  }

  @include media-query($on-palm) {
    .intro-img-col {
      max-width: unset;
    }
  }
}

/*
 * row and col for footer and intro
 */

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: math.div($spacing-unit, 2);
  gap: math.div($spacing-unit, 2);

  .col {
    flex-grow: 1;
    width: 50%;
  }
}

@include media-query($on-palm) {
  .row {
    flex-direction: column;

    .col {
      width: 100%;
    }
  }
}

/**
 * Profile cards
 */
.hidden {
  display: none !important;
}

.profile-card {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-style: italic;
  color: $brand-white;
  background-color: $contrast-color;

  .profile-img {
    cursor: pointer;
    width: 15rem;
    height: 15rem;
  }

  .profile-text {
    width: calc(100% - 15rem); // workaround for firefox bug
    text-align: justify;
    font-weight: bolder;
    padding-left: math.div($spacing-unit, 2);
    padding-right: math.div($spacing-unit, 2);
  }
}

@include media-query($on-palm) {
  .profile-card:not(.hidden) {
    flex-direction: column;

    text-align: justify;

    .profile-img {
      height: auto;
      width: 100%;
    }

    .profile-text {
      width: unset;
    }
  }
}

/**
 * Site footer
 */
.site-footer {
  padding: $spacing-unit 0;
  font-size: 15px;
  color: $grey-color;
  @extend %clearfix;
  font-weight: bolder;
  background-color: $footer-color;
  color: $brand-white;

  svg {
    path {
      fill: $brand-white;
    }
  }

  a {
    color: $brand-white;
    font-weight: bold;
  }

  ul {
    margin-bottom: 0;
  }

  .heading {
    font-size: 2rem;
    font-style: italic;
    text-align: center;
    margin-bottom: math.div($spacing-unit, 2);
  }

  .contact-list,
  .social-media-list {
    list-style: none;
    margin-left: 0;
  }

  @include media-query($on-palm) {
    .row.links {
      text-align: center;
      gap: unset;

      // overwrite default row / col behaviour:
      flex-direction: row;
      .col {
        max-width: 50%;
      };

      ul {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }

      .icon > svg {
        width: 2rem;
        height: 2rem;
      }
    }

    .d-sm-none {
      display: none;
    }
  }
}


/**
 * Page content
 */
.page-content {
  padding-bottom: $spacing-unit;
  display: flex;
  hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
}

.page-heading {
  font-size: 2rem;
  text-align: center;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.postlist_entry {
  margin-bottom: $spacing-unit;

  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-meta-small {
  font-size: $tiny-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  font-size: 24px;
}

.headertaglist {
  margin-bottom: 0px;
}

/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  font-size: 42px;
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    font-size: 36px;
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    font-size: 32px;

    @include media-query($on-laptop) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 26px;

    @include media-query($on-laptop) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 20px;

    @include media-query($on-laptop) {
      font-size: 18px;
    }
  }

  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .pdf {
    margin-bottom: math.div($spacing-unit, 2);

    object,
    embed,
    iframe {
      width: 100%;
      height: 75vh;
      background: $background-color;
    }

    // &::before {
    //   content: "Your browser does not support embedding PDFs.";
    //   top: +3rem;
    //   display: block;
    //   position: relative;
    //   width: 100%;
    //   text-align: center;
    // }
  }
}

.pagination {
  text-align: center;
}

.rss-subscribe {
  text-align: right;
}

.blogmap {
  height: 30vh;
}

ul.language-switcher {
  margin-left: 0;
  list-style: none;
  display: flex;
  flex-direction: row;

  li {
    display: inline;
    flex-grow: 1;

    .flag {
      width: 1rem;
      height: 0.75rem;
    }

    &.de:lang(de),
    &.en:lang(en) {
      .btn {
        box-shadow: 0 0 2px 2px rgba(0, 0 ,0 , .25) inset;
      }
    }
  }
}
