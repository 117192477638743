@charset "utf-8";

@use 'sass:math';

// Our variables
$base-font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 300;
$small-font-size:  $base-font-size * 0.875;
$tiny-font-size:   $small-font-size * 0.875;
$base-line-height: 1.5;

$headline-font-family: "Lato", Helvetica, Arial, sans-serif;
$headline-font-weight: 700;

$font-sans: "Lato", Helvetica, Arial, sans-serif;

$spacing-unit:     2rem;

$text-color:       #111;
$background-color: #FDFDFD;
$brand-color:      #2A7AE2;
$brand-green:      #41A62A;
$brand-white:      #FFF;
$contrast-color:   #ff388b;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$border-color:     $grey-color;
$header-color-light: $background-color;
$footer-color:     $contrast-color;

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;

// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: math.div($spacing-unit, 2);
//         padding-left: math.div($spacing-unit, 2);
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting",
        "tags"
;
