@use 'sass:math';

/**
 * Reset some basic elements
 */
body,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
  margin: 0;
  padding: 0;
}

/**
 * Basic styling
 */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  counter-reset: sidenote-counter;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure,
%vertical-rhythm {
  margin-bottom: math.div($spacing-unit, 2);
}

/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}

/**
 * Figures
 */
figure {
  display: flex;
  flex-direction: column;
  align-items: center;
}

figcaption {
  font-size: $small-font-size;
}

/**
 * Lists
 */
ul,
ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $headline-font-weight;
  font-family: $headline-font-family;
}

/**
 * Links
 */
a {
  color: $brand-color;
  text-decoration: none;

  &:visited {
    color: darken($brand-color, 15%);
  }

  &:hover {
    color: $text-color;
    text-decoration: underline;
  }
}

/**
 * Blockquotes
 */
blockquote p {
  color: $grey-color;
  border-left: 4px solid $grey-color-light;
  padding-left: math.div($spacing-unit, 2);
  font-size: 1.1rem;
  line-height: 1.3;
  margin-top: 1.78181818rem;
  margin-bottom: 1.78181818rem;
  width: 65%;
  padding-left: 2.5%;
  padding-right: 2.5%;

  > :last-child {
    margin-bottom: 0;
  }
}

blockquote footer {
  width: 45%;
  text-align: right;
}

/**
 * Code formatting
 */
pre,
code {
  font-size: 15px;
  border: 1px solid $grey-color-light;
  border-radius: 3px;
  background-color: #eef;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

/*-- Table styling section - For Tufte-Jekyll, booktabs style is default for Markdown tables  --*/

table,
table.booktabs {
  width: auto; //making booktabs style tables the unstyled default in case someone uses Markdown styling
  margin: 1rem auto 1rem 10px;
  //margin-left: 10px;
  border-spacing: 0px;
  border-top: 2px solid $border-color;
  border-bottom: 2px solid $border-color;
}

.booktabs th,
th {
  border-bottom: 1px solid $border-color;
  padding: 0.65ex 0.5em 0.4ex 0.5em;
  font-weight: normal;
  text-align: center;
}

th,
td {
  font-size: 1.2rem;
  line-height: 1.71428571;
}

.booktabs th.cmid,
th {
  border-bottom: 1px solid lighten($border-color, 25%);
}

.booktabs th.nocmid {
  border-bottom: none;
}

.booktabs tbody tr:first-child td,
tr:first-child td {
  padding-top: 0.65ex;
} /* add space between thead row and tbody */

.booktabs td,
td {
  padding-left: 0.5em;
  padding-right: 0.5em;
  text-align: left;
}

.booktabs caption,
caption {
  font-size: 90%;
  text-align: left;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1ex;
  caption-side: top;
}

.booktabs tbody tr td.l {
  text-align: left !important;
}
.booktabs tbody tr td.c {
  text-align: center !important;
}
.booktabs tbody tr td.r {
  text-align: right !important;
}

.table-caption {
  float: right;
  clear: right;
  margin-right: -60%;
  width: 50%;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.96;
}
/* -- End of Table Styling section --*/

figure img.maincolumn {
  max-width: 65%;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  margin-bottom: 3em;
}

figcaption {
  margin-top: $spacing-unit * 0.25;
  font-size: $tiny-font-size;
  line-height: 1.6;
  vertical-align: baseline;
}

img {
  max-width: 100%;
}

.sidenote,
.marginnote {
  float: right;
  clear: right;
  margin-right: -60%;
  width: 50%;
  margin-top: 0;
  margin-bottom: 1.96rem;
  font-size: $tiny-font-size;
  line-height: 1.3; //changed to bring line heights into rational pattern
  vertical-align: baseline;
  position: relative;
}

li .sidenote,
li .marginnote {
  margin-right: -80%;
} //added to allow for the fact that lists are indented and marginnotes and sidenotes push to right

blockquote .sidenote,
blockquote .marginnote {
  margin-right: -79%;
}

.sidenote-number {
  counter-increment: sidenote-counter;
}

.sidenote-number:after,
.sidenote:before {
  content: counter(sidenote-counter) " ";
  color: $contrast-color; //added color
  position: relative;
  vertical-align: baseline;
}

.sidenote-number:after {
  content: counter(sidenote-counter);
  font-size: $tiny-font-size;
  top: -0.5rem;
  left: 0.1rem;
}

.sidenote:before {
  content: counter(sidenote-counter) ".\000a0"; // this is unicode for a non-breaking space
  color: $contrast-color;
  top: 0rem;
} //removed superscripting for numerical reference in sidenote

div.table-wrapper {
  overflow-x: auto;
} //changed all overflow values to 'auto' so scroll bars appear only as needed

@media screen and (max-width: 760px) {
  .wapper {
    pre code {
      width: 87.5%;
    }
    ul {
      width: 85%;
    }
    figcaption,
    figure.fullwidth figcaption {
      margin-right: 0%;
      max-width: none;
    }
    blockquote p,
    blockquote footer {
      width: 80%;
      padding-left: 5%;
      padding-right: 5%;
    }
  }
}

.marginnote code,
.sidenote code {
  font-size: 1rem;
} //more .code class removal

pre,
pre code,
p pre code {
  //width: 52.5%;
  //padding-left: 2.5%;
  overflow-x: auto;
}

.fullwidth,
li.listing div {
  max-width: 100%;
}

.full-width {
  .sidenote,
  .sidenote-number,
  .marginnote {
    display: none;
  }
}

span.newthought {
  font-variant: small-caps;
  font-size: 1.2em;
  letter-spacing: 0.05rem;
}

input.margin-toggle {
  display: none;
}

label.sidenote-number {
  display: inline;
}

label.margin-toggle:not(.sidenote-number) {
  display: none;
}

@media (max-width: 760px) {
  label.margin-toggle:not(.sidenote-number) {
    display: inline;
    color: $contrast-color;
  }
  .sidenote,
  .marginnote {
    display: none;
  }
  .margin-toggle:checked + .sidenote,
  .margin-toggle:checked + .marginnote {
    display: block;
    float: left;
    left: 1rem;
    clear: both;
    width: 95%;
    margin: 1rem 2.5%;
    vertical-align: baseline;
    position: relative;
  }
  label {
    cursor: pointer;
  }
  pre,
  pre code,
  p code,
  p pre code {
    width: 90%;
    padding: 0;
  }
  .table-caption {
    display: block;
    float: right;
    clear: both;
    width: 98%;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 1%;
    margin-right: 1%;
    vertical-align: baseline;
    position: relative;
  }
  div.table-wrapper,
  table,
  table.booktabs {
    width: 85%;
  }
  div.table-wrapper {
    border-right: 1px solid #efefef;
  }
  img {
    max-width: 100%;
  }
}

/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width: calc(#{$content-width} - (#{$spacing-unit} * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit;
  padding-left: $spacing-unit;
  @extend %clearfix;

  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width: calc(#{$content-width} - (#{$spacing-unit}));
    padding-right: math.div($spacing-unit, 2);
    padding-left: math.div($spacing-unit, 2);
  }
}

/**
 * Clearfix
 */
%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

/**
 * Icons
 */
.icon {
  > svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;

    path {
      fill: $grey-color;
    }
  }
}

.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-right {
  text-align: right;
}
@include media-query($on-palm) {
  .sm-text-center {
    text-align: center;
  }
}

/**
 * Buttons
 */
.btn {
  border: none;
  display: block;
  padding: 0.5rem;
  line-height: $base-line-height;

  font-weight: 700;
  font-family: Lato;
  font-style: italic;
  text-align: center;

  color: $brand-white;
  background: $brand-color;

  &:hover {
    background: $contrast-color;
    text-decoration: none;
  }
}
a.btn:visited {
  color: $brand-white;
}

.bg-contrast {
  background: $contrast-color;
  color: #fff;
}

.p-1 {
  padding: math.div($spacing-unit, 2);
}
.p-2 {
  padding: $spacing-unit;
}
.mb-0 {
  margin-bottom: 0 !important;
}

::selection {
  background-color: $brand-color;
  color: $brand-white;
}
